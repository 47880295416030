import { Box, Button, Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import API from '../../api/api'
import { declineLineError, updateFbFileStatus, validateLineError } from '../../api/mutations/fileMutations.js'
import ActionMenu from '../../components/ActionMenu'
import Alert from '../../components/AlertSnackbar/index.jsx'
import SumCard from '../../components/Card/SumCard'
import CustomizedCheckbox from '../../components/Form/CustomizedCheckbox'
import FbPadForm from '../../components/Form/FbPadForm/index.jsx'
import FbPadLinesTable from '../../components/Table/FbPadLinesTable'
import LoadingTable from '../../components/Table/LoadingTable'
import { UserContext } from '../../contexts/userContext.jsx'
import { DEFAULT_SUCCESS_ALERT, FB_PAD_ACTIONS, FILE_ACTIONS } from '../../utils/constants'
import { formatDate } from '../../utils/format.js'
import { calculateDuration, getFormTitle, getNewState, getNewValueState, getSumCards } from './functions'

export default class FbFileZoom extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      alert: {
        open: false,
        message: '',
        severity: '',
      },
      file: {},
      openFile: false,
      selectedFile: {},
      formAction: null,
      formTitle: '',
      errors: {
        AML: false,
        AMA: false,
        PAD: false,
      },
      nextFileId: null,
      isCompleted: false,
      totalSum: {
        AML: { amount: null, volume: null },
        AMA: { amount: null, volume: null },
        PAD: { amount: null, volume: null },
      },
      stopAML: null,
    }
  }

  static contextType = UserContext

  async componentDidMount() {
    this.setState(await getNewState(this.props.match.params.id, this.props.location.state.fbPadFiles))
  }

  async componentDidUpdate() {
    // Case the user wants to access the next sales point
    if (this.state.file.id && parseInt(this.props.match.params.id) !== this.state.file.id)
      this.setState(await getNewState(this.props.match.params.id, this.props.location.state.fbPadFiles))
  }

  _handleMenu = async ({ action, alert }) => {
    if (Object.entries(alert).length > 0) {
      this.setState({ alert })
      return
    }
    if (action === FB_PAD_ACTIONS.validateError) {
      await API.queryAPI(validateLineError(this.state.selectedFile.id))
      this.setState(await getNewState(this.props.match.params.id, this.props.location.state.fbPadFiles))
    } else if (action === FB_PAD_ACTIONS.declineError) {
      await API.queryAPI(declineLineError(this.state.selectedFile.id))
      this.setState(await getNewState(this.props.match.params.id, this.props.location.state.fbPadFiles))
    } else this.setState({ formAction: action, formTitle: getFormTitle(action), openFile: true })
  }

  _handleSaveVolume = (alert, newFile) => {
    if (!newFile) {
      this.setState({ alert })
      return
    }
    this.setState({ alert, ...getNewValueState(this.state, newFile) })
  }

  _handleValidate = async (goToNext) => {
    if (this.state.isCompleted) {
      await API.queryAPI(updateFbFileStatus(this.state.file.id, true))
      this.setState({
        alert: DEFAULT_SUCCESS_ALERT,
        isCompleted: false,
      })
    }
    if (goToNext) this._goToNextFbFile()
    else this.props.history.push(`/france-boissons/${this.props.location.state.id}`)
  }

  _goToNextFbFile = () => {
    if (!this.state.nextfileId) this.props.history.push(`/france-boissons/${this.props.location.state.id}`)
    else
      this.props.history.push({
        pathname: `/fb-files/${this.state.nextfileId}`,
        state: this.props.location.state,
      })
  }

  _renderFbPadLinesTable() {
    if (!this.state.file.fbPadLines) return <LoadingTable />
    const files = this.state.file.fbPadLines.filter((line) => line.lineStatus !== 3)
    return (
      <>
        {files.length > 0 && (
          <FbPadLinesTable
            key={`${this.state.file.id}-PAD`}
            files={files}
            hasCategory={false}
            hideAmounts={this.state.errors}
            selectedFile={this.state.selectedFile}
            status={this.state.file.status}
            title="PAD"
            onMenuClick={this._handleMenu}
            onSelectFile={(file) => this.setState({ selectedFile: file })}
            onSave={this._handleSaveVolume}
          />
        )}
        {files.length === 0 && (
          <Box display="flex" justifyContent="flex-end">
            <ActionMenu
              items={['Ajouter un dossier']}
              onMenuClick={() => this._handleMenu({ action: FILE_ACTIONS.addFile, alert: {} })}
            />
          </Box>
        )}
      </>
    )
  }

  render() {
    const date1 = new Date(this.state.file.startingDate)
    const date2 = new Date(this.state.file.endingDate)
    const months = calculateDuration(date1, date2, 2023)

    return (
      <Grid container spacing={1}>
        <Alert
          open={this.state.alert.open}
          severity={this.state.alert.severity}
          text={this.state.alert.message}
          onClose={() => this.setState({ alert: { ...this.state.alert, open: false } })}
        />
        <Grid item xs={12} sm={3}>
          <Typography variant="h5">{this.state.file.ownerName}</Typography>
          <Typography variant="h5">{this.state.file.salespointName}</Typography>
          <Typography variant="body1">
            Dossier PAD:
            <br />
            {this.state.file.service}
          </Typography>
          <Typography variant="body1">{this.state.file.contentious?.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1">Code Cassiopae: {this.state.file.cassiopaeCode}</Typography>
          <Typography variant="body1">Code SPARC: {this.state.file.sparcCode}</Typography>
          <Typography variant="body1">Code Totem: {this.state.file.totemCode}</Typography>
          <Typography variant="body1">Date de début: {formatDate(date1)}</Typography>
          <Typography variant="body1">Date de fin: {formatDate(date2)}</Typography>
          <Typography variant="body1">Durée: {`${months > 12 ? 12 : months} mois`}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {this.state.file.fbPadLines && (
            <SumCard
              disclaimer={
                'Les informations présentées ne prennent pas en compte les potentiels impayés dans anciennes gestion PAD'
              }
              horizontalDisplay={true}
              sums={getSumCards(this.state.file)}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {this._renderFbPadLinesTable()}
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <CustomizedCheckbox
              isDisabled={this.state.file?.fbPadLines?.some((line) => line.lineStatus === 2) ?? false}
              isCompleted={this.state.isCompleted}
              label="Je déclare ce PDV complet"
              onChange={() => this.setState({ isCompleted: !this.state.isCompleted })}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              variant="contained"
              disabled={!this.state.isCompleted}
              onClick={() => this._handleValidate(false)}
              color="secondary"
            >
              Valider
            </Button>
          </Box>
        </Grid>

        {this.state.openFile && (
          <FbPadForm
            action={this.state.formAction}
            open={this.state.openFile}
            file={this.state.file}
            selectedFile={this.state.selectedFile}
            title={this.state.formTitle}
            onClose={() => this.setState({ selectedFile: {}, openFile: false })}
            onValidate={async () => {
              const newState = await getNewState(this.props.match.params.id, this.props.location.state.fbPadFiles)
              this.setState({
                selectedFile: {},
                openFile: false,
                alert: DEFAULT_SUCCESS_ALERT,
                ...newState,
              })
            }}
          />
        )}
      </Grid>
    )
  }
}

FbFileZoom.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}
