export const FbPadFileQuery = (FbPadFileId) => {
  return `{
    fbPadFile(id: ${FbPadFileId}) {
      id
      cassiopaeCode
      sparcCode
      totemCode
      nbSalespoint
      annualAmount
      error {
        name
      }
      salespointName
      ownerName
      service
      status
      startingDate
      endingDate
      contentious {
        name
      }
      fbPadLines {
        id
        discount_HE
        discount_FB
        free_HE
        free_FB
        volume
        product {
          id
          name
          category {
            id
            name
          }
        }
        error {
          name
        }
        tax_rate
        unit
        lineStatus
        replaceLine {
          id
        }
      }
    }
  }`
}
