import { MsalProvider } from '@azure/msal-react'
import { createTheme, ThemeProvider } from '@material-ui/core'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css'
import HomeLayout from './components/Layout/HomeLayout'
import routes from './routes'
import { msalInstance } from './services/msal'
require('dotenv').config()

const theme = createTheme({
  palette: {
    primary: {
      main: '#205527',
    },
    secondary: {
      main: '#000',
    },
  },
})

export default class App extends React.Component {
  render() {
    return (
      <MsalProvider instance={msalInstance}>
        <Router>
          <ThemeProvider theme={theme}>
            <HomeLayout>
              <Switch>
                {routes.map((route) => (
                  <Route exact key={route.path} path={route.path} render={(props) => <route.component {...props} />} />
                ))}
              </Switch>
            </HomeLayout>
          </ThemeProvider>
        </Router>
      </MsalProvider>
    )
  }
}
