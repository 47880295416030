import { Box, Grid, Paper, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import API from '../../api/api'
import { updateDistributorEmail } from '../../api/mutations/distributorMutations'
import { addSalesPoint } from '../../api/mutations/salesPointMutations'
import { fbDistributorQuery } from '../../api/queries/distributorQueries'
import Alert from '../../components/AlertSnackbar/index.jsx'
import DistributorReportingButton from '../../components/Button/DistributorReportingButton'
import ServiceInfoCard from '../../components/Card/ServiceInfoCard'
import SalesPointAttachmentForm from '../../components/Form/SalesPointAttachmentForm'
import SalesPointForm from '../../components/Form/SalesPointForm/index.jsx'
import CommonProgressBar from '../../components/ProgressBar'
import FbFilesTable from '../../components/Table/FbFilesTable'
import LoadingTable from '../../components/Table/LoadingTable'
import { UserContext } from '../../contexts/userContext'
import { ALERT_MESSAGE, ERRORS, SALESPOINT_ACTIONS } from '../../utils/constants'
import { getCardsValues, getServicesStatusErrors } from './function'

export default class FbZoom extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      distributor: {},
      openSalesPointForm: false,
      openAttachmentForm: false,
      alertMessage: null,
      alertText: '',
      openAlert: false,
      selectedFiles: null,
      errors: { AML: false, AMA: false, PAD: false },
    }
  }

  static contextType = UserContext

  async componentDidMount() {
    const distributorResult = await API.queryAPI(fbDistributorQuery(this.props.match.params.id))

    const errors = getServicesStatusErrors(distributorResult.distributor.fbPadFiles)
    this.setState({ distributor: distributorResult.distributor, errors: errors })
  }

  _handleMenu = async (action) => {
    if (action === SALESPOINT_ACTIONS.addSalesPoint) this.setState({ openSalesPointForm: true })
    else if (action === SALESPOINT_ACTIONS.updateDistributor) {
      if (this.state.selectedSalesPoint === null) {
        this.setState({
          openSalesPointForm: false,
          openAttachmentForm: false,
          alertMessage: 'warning',
          alertText: 'Aucun point de vente sélectionné',
          openAlert: true,
        })
        return
      }
      this.setState({ openAttachmentForm: true })
    }
  }

  _closeForm() {
    this.setState({
      openSalesPointForm: false,
      openAttachmentForm: false,
      alertMessage: 'success',
      alertText: ALERT_MESSAGE.success,
      openAlert: true,
    })
  }

  _addSalesPoint = async (data) => {
    const user = this.context
    const newSalesPointResult = await API.queryAPI(
      addSalesPoint({
        ...data,
        distributorId: this.state.distributor.id,
        adLogin: user.userLogin,
        errorId: ERRORS.missingSalesPoint,
      }),
    )
    this.setState({
      distributor: {
        ...this.state.distributor,
        salesPoints: [...this.state.distributor.salesPoints, newSalesPointResult.addSalesPoint],
      },
    })
    this._closeForm()
  }

  _getFilesSatus() {
    return this.state.distributor?.fbPadFiles?.map((file) => {
      return file.status
    })
  }

  _getCompletedVolumesPercent() {
    const salesPointsStatus = this._getFilesSatus()
    return salesPointsStatus
      ? Math.round(
          (this._getFilesSatus()?.filter((status) => status === true).length / salesPointsStatus?.length) * 100,
        )
      : 0
  }

  async handleClickUpdateEmail() {
    if (!!this.state.distributor.email) {
      await API.queryAPI(updateDistributorEmail(this.props.match.params.id, this.state.distributor.email))
    }
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Alert
          open={this.state.openAlert}
          severity={this.state.alertMessage}
          text={this.state.alertText}
          onClose={() => this.setState({ openAlert: false })}
        />
        <Grid item xs={12}>
          <Typography variant="h5">{this.state.distributor.name}</Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} sm={6}>
            <CommonProgressBar value={this._getCompletedVolumesPercent()} title="Etat général de la collecte" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DistributorReportingButton
              areVolumesCompleted={this._getFilesSatus()?.every((status) => status === true)}
              distributorId={this.state.distributor.id}
              status={this.state.distributor.status?.id}
              onUpdate={(newStatus) => this.setState({ distributor: { ...this.state.distributor, status: newStatus } })}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1} direction="row" justifyContent="space-evenly">
          {Object.entries(this.state.distributor).length > 0 &&
            getCardsValues(this.state.distributor, this.state.errors).map((card, index) => (
              <Box key={index} mb={1}>
                <ServiceInfoCard
                  title={card.title}
                  volume={card.volume}
                  amount={card.amount}
                  className={card.className}
                />
              </Box>
            ))}
        </Grid>
        <Grid item xs={12}>
          <Paper>
            {!this.state.distributor.fbPadFiles ? (
              <LoadingTable />
            ) : (
              this.state.distributor.fbPadFiles && (
                <FbFilesTable
                  title="PAD HE"
                  entity="HE"
                  distributor={this.state.distributor}
                  selectedFiles={this.state.selectedFiles}
                  onMenuClick={this._handleMenu}
                  onSelect={(files) => this.setState({ selectedFiles: files })}
                />
              )
            )}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            {!this.state.distributor.fbPadFiles ? (
              <LoadingTable />
            ) : (
              this.state.distributor.fbPadFiles && (
                <FbFilesTable
                  title="PAD FB"
                  entity="FB"
                  distributor={this.state.distributor}
                  selectedFiles={this.state.selectedFiles}
                  onMenuClick={this._handleMenu}
                  onSelect={(files) => this.setState({ selectedFiles: files })}
                />
              )
            )}
          </Paper>
        </Grid>
        {this.state.openSalesPointForm && (
          <SalesPointForm
            distributorName={this.state.distributor.name}
            open={this.state.openSalesPointForm}
            salesPoints={this.state.distributor.salesPoints}
            onClose={() => this.setState({ openSalesPointForm: false })}
            onValidate={this._addSalesPoint}
          />
        )}
        {this.state.openAttachmentForm && (
          <SalesPointAttachmentForm
            distributor={{ id: this.state.distributor.id, name: this.state.distributor.name }}
            salesPoint={this.state.selectedSalesPoint}
            open={this.state.openAttachmentForm}
            onClose={() => this.setState({ openAttachmentForm: false })}
            onValidate={(newSalesPoint) => {
              const salesPoints = this.state.distributor.salesPoints
              const currentSalesPointIndex = salesPoints.indexOf(this.state.selectedSalesPoint)
              salesPoints[currentSalesPointIndex] = newSalesPoint
              this.setState({
                distributor: {
                  ...this.state.distributor,
                  salesPoints: salesPoints,
                },
              })
              this._closeForm()
            }}
          />
        )}
      </Grid>
    )
  }
}

FbZoom.propTypes = {
  match: PropTypes.object,
}
