import { file } from '../common'

export const addFile = (data) => {
  return `mutation {
    addFile(      
      cassiopaeCode: "${data.cassiopaeCode}",
      sparcCode: "${data.sparcCode}",
      discount: ${data.discount},
      volume: ${data.volume},
      startingDate: "${data.startingDate}",
      endingDate: "${data.endingDate}",
      productId: ${data.product},
      serviceId: ${data.service},
      salesPointId: ${data.salesPointId},
      errorId: ${data.errorId}
    ){
      ${file}
    }
  }`
}

export const updateFileVolume = (id, volume) => {
  return `mutation {
    updateFileVolume(fileId: ${id}, volume: ${volume}) {
      id
      volume
      amount
      error {
        id
        name
      }
    }
  }`
}

export const updateFileEndingDate = (filesId, endingDate) => {
  return `mutation {
    updateFileEndingDate(fileId: ${filesId}, endingDate: "${endingDate}") {
      id
      endingDate
      error {
        id
        name
      }
    }
  }`
}

export const updateDiscountFileByIds = (filesIds, discount) => {
  return `mutation {
    updateDiscountFileByIds(ids: [${filesIds}], discount: ${discount}) {
      id
      discount
      error {
        id
        name
      }
    }
  }`
}

export const updateDatesFileByIds = (filesIds, startingDate, endingDate) => {
  return `mutation {
    updateDatesFileByIds(ids: [${filesIds}], startingDate: "${startingDate}", endingDate: "${endingDate}") {
      id
      startingDate
      endingDate
      error {
        id
        name
      }
    }
  }`
}

export const updateFilesVolumeBySalesPoint = (volume, salesPointId) => {
  return `mutation {
    updateFilesVolumeBySalesPoint(volume:${volume} , salesPointId: ${salesPointId}) {
      id
      volume
    }
  }`
}

export const updateFileStopAML = (sparcCode, stopAML) => {
  return `mutation {
    updateFileStopAML(sparcCode:"${sparcCode}" , stopAML: ${stopAML}) {
      id
      stopAML
    }
  }`
}

export const updateFbFileStatus = (fileId, status) => {
  return `mutation {
    updateFbFileStatus(fileId: ${fileId}, status: ${status}) {
      id
      status
    }
  }`
}

export const updateFbLine = (lineId, volume, discount_HE, discount_FB, free_HE, free_FB) => {
  return `mutation {
    updateFbLine(lineId: ${lineId}, volume: ${volume}, discountHE: ${discount_HE}, discountFB: ${discount_FB}, freeHE: ${free_HE}, freeFB: ${free_FB}) {
      id
      volume
      discount_HE
      discount_FB
      free_HE
      free_FB
    }
  }`
}

export const validateLineError = (lineId) => {
  return `mutation {
    validateLineError(lineId: ${lineId}) {
      id
      lineStatus
    }
  }`
}

export const declineLineError = (lineId) => {
  return `mutation {
    declineLineError(lineId: ${lineId}) {
      id
      lineStatus
    }
  }`
}

export const addFbPadLine = (padFileId, productId, volume, discountHE, discountFB, freeHE, freeFB, taxRate) => {
  return `mutation {
  addFbPadLine(padFileId: ${padFileId}, productId: ${productId}, volume: ${volume}, discountHE: ${discountHE}, discountFB: ${discountFB}, freeHE: ${freeHE}, freeFB: ${freeFB}, taxRate: ${taxRate}) {
      id
    }
  }`
}
